<template>
  <div>
    <h1 class="main-header" v-translate translate-context="form'">Create</h1>
    <Form :data=empty @save="onSave" :save_text="$pgettext('form','Create')"/>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Form from "@/domain/region/basePlan/Form.vue";
import {SuccessNotification} from "@/domain/core/exception/exceptions";

export default {
  name: 'NewBasePlan',
  components: {Form},
  data() {
    return {
      empty: {
        name: {},
        description: {},
        price: '',
        is_active: true,
        is_default: false,
        template: null,
        vertical: null,
        plan: {
          num_photo_included: null,
          extra_photo_price: null,
          num_max_photo: null,
          max_participants: null,
          is_promo: false,
        },
      }
    }
  },
  computed: {
    ...mapGetters('region', [
      'regionId',
      'categoryId'
    ]),
  },
  mounted() {
    this.setRegionId(this.$route.params.region)
    this.setCategoryId(this.$route.params.category)
  },
  methods: {
    ...mapActions('region', [
      'createBasePlan',
      'setRegionId',
      'setCategoryId'
    ]),
    async onSave(data) {
      await this.createBasePlan(data)
      await this.$router.push({name: 'base_plan.list', params: {region: this.regionId, category: this.categoryId}})
      throw new SuccessNotification(this.$pgettext('base_plan', 'Base Plan created!'))
    }
  }
}
</script>